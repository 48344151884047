import { Divider, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import { useStyles } from "../../styles/footerStyles";

import DownloadApp from "../../assets/icons/download-app.svg";
import DownloadGg from "../../assets/icons/download-gg.svg";
import FbIcon from "../../assets/icons/fb.svg";
import GC from "../../assets/icons/gc-2.svg";
import GmailIcon from "../../assets/icons/gmail.svg";
import Gsale from "../../assets/icons/gsale-2.svg";
import Hotline from "../../assets/icons/hotline.svg";
import Linkedin from "../../assets/icons/linkedin.svg";
import SiteIcon from "../../assets/icons/website.svg";
import YoutubeIcon from "../../assets/icons/youtube.svg";
import ZaloIcon from "../../assets/icons/zalo.svg";

const Footer = ({ dispatch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const spacing = isMobile ? 1.5 : 2;

  return (
    <>
      <div className={classes.footer}>
        <Grid container spacing={spacing}>
          <Grid item xs={12} md={2}>
            <img src={Gsale} alt="gsale" className="logo" />
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <div className="normal-text bold">Tổng đài CSKH</div>
            <div className="normal-text">
              Dịch vụ chăm sóc khách hàng trực tuyến của GSale
            </div>
            <div className="normal-text">Thời gian:</div>
            <div>Thứ 2 - thứ 6 (9h00 - 18h00)</div>
          </Grid>
          <Grid item xs={12} sm={6} md={2.5}>
            <div className="normal-text bold">Thông tin liên hệ</div>
            <a href="tel:1900292987">
              <img src={Hotline} alt="hotline" className="img" />
            </a>
            <div className="row">
              <img src={GmailIcon} alt="gmail" />
              <a href="mailto:cskh@globalcare.vn" className="text margin-left">
                cskh@glogbalcare.vn
              </a>
            </div>
            <div className="row">
              <img src={SiteIcon} alt="site" />
              <a
                href="https://gsale.com.vn/"
                target="_bank"
                className="text margin-left"
              >
                www.gsale.com.vn
              </a>
            </div>
          </Grid>
          <Grid item xs={6} sm={6} md={2.5}>
            <div className="normal-text bold">Hỗ trợ</div>
            <div
              className="text"
              onClick={() => dispatch({ type: "selectNav", id: 4 })}
            >
              Hỏi đáp
            </div>
            <a
              href="https://drive.google.com/file/d/1eGJqc0RkpqD0DJnpN0JahzmOnj6W-GXA/view?usp=sharing"
              target="_bank"
              className="text"
            >
              <div>Hướng dẫn sử dụng</div>
            </a>
            <a
              href="https://globalcare.com.vn/tin-tuc/"
              target="_bank"
              className="text"
            >
              <div>Tin tức</div>
            </a>
          </Grid>
          <Grid item xs={6} sm={6} md={2.5}>
            <div className="normal-text bold">Cài đặt GSale</div>
            <a
              href="https://core.globalcare.vn/app/gsale/download"
              target="_bank"
            >
              <img src={DownloadApp} alt="down-app" className="down-img" />
              <img src={DownloadGg} alt="down-gg" className="down-img" />
            </a>
          </Grid>
        </Grid>
        <Divider className="divider" />
        <Grid container spacing={spacing}>
          <Grid item xs={12} md={2}>
            <img src={GC} alt="gsale" className="logo-gc" />
          </Grid>
          <Grid item xs={6} sm={6} md={2.5}>
            <div className="normal-text bold">Về chúng tôi</div>
            <a
              href="https://globalcare.com.vn/ve-chung-toi/"
              target="_bank"
              className="text"
            >
              <div>Tìm hiểu về Global Care</div>
            </a>
            <a
              href="https://drive.google.com/drive/folders/147chhBxF0k7-lDWeeFLiG1mRIdkhtYFK"
              target="_bank"
              className="text"
            >
              <div>Tải tài liệu</div>
            </a>
            <a
              href="https://globalcare.com.vn/cong-tac-vien/"
              target="_bank"
              className="text"
            >
              <div>Cộng tác viên</div>
            </a>
          </Grid>
          <Grid item xs={6} sm={6} md={2.5}>
            <div className="normal-text bold">Các liên kết khác</div>
            <a
              href="https://globalcare.com.vn/wp-content/uploads/2022/09/Dieu-khoan-su-dung.docx.pdf"
              target="_bank"
              className="text"
            >
              <div>Điều khoản sử dụng</div>
            </a>
            <a
              href="https://drive.google.com/file/d/1d5v43035RIvTy9DarxEJ6sVJyHBIER_3/view"
              target="_bank"
              className="text"
            >
              <div>Chính sách bảo mật</div>
            </a>
            <a
              href="https://drive.google.com/file/d/1bCyfiHUdBhI-We59-gR34vwBOu8ekqMG/preview"
              target="_bank"
              className="text"
            >
              <div>Phản ánh & Góp ý</div>
            </a>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className="normal-text bold">
              Công ty Cổ phần Tư vấn Global Care
            </div>
            <div className="normal-text">
            Lầu 1B, 121 Quốc Hương, Phường Thảo Điền, TP Thủ Đức, TP Hồ Chí Minh, Việt Nam
            </div>
            <div className="row-icon">
              <a href="https://zalo.me/1747508283619492630" target="_bank">
                <img src={ZaloIcon} alt="zalo" className="icon" />
              </a>
              <a href="https://www.facebook.com/Gsalevn" target="_bank">
                <img src={FbIcon} alt="fb" className="icon" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCVCpGwSPErtw3OyLQYwwulw"
                target="_bank"
              >
                <img src={YoutubeIcon} alt="youtube" className="icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/global-care-company/ "
                target="_bank"
              >
                <img src={Linkedin} alt="linkedin" className="icon" />
              </a>
            </div>
            {/* <img src={Sign} alt="sign" /> */}
          </Grid>
        </Grid>
      </div>
      <div className={classes.right}>
        All rights reserved by Global Care 2022
      </div>
    </>
  );
};

export default Footer;
